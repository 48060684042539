import axios from "axios";

export function getBaseUrl(): string {
    if (process.env.NODE_ENV === 'production') {
        return 'https://api.spelhuis.be/api/';
    } else {
        return 'http://spelhuis-api.test/api/'
    }
}

export function setupInterceptors() {
    const maintenancePath = '/maintenance';

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {

        if (error.response?.status === 503 && window.location.pathname !== maintenancePath) {
            // Backend is in maintenance mode
            window.location.pathname = maintenancePath;
        }

        return error.response;
    });
}
